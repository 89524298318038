import {
  SortOrder,
  type PaginationConfig,
  type RecordEntity,
  type RecordEntityId,
} from '../records';
import type {
  Category,
  CategoryToItemRelationship,
  Item,
  ItemToModifierGroupRelationship,
  Menu,
  Modifier,
  ModifierGroup,
  ModifierGroupToModifierRelationship,
} from '../menu';

export interface GlobalMenuGroup extends RecordEntity {
  name: string;
  is_active: boolean;
  posid_segment: string;
  published_brands?: Brand[];
  draft_brands?: Brand[];
  version?: number;
  auto_push_to_local?: boolean;
}

export interface LocalMenuGroupToSiteRelationship extends RecordEntity {
  site_id: RecordEntityId;
  local_menu_group_id: RecordEntityId;
  site_name: string;
  local_menu_group_name: string;
}

export interface LocalMenuGroup extends RecordEntity {
  name: string;
  is_active?: boolean;
  allowed_global_menu_groups?: Pick<GlobalMenuGroup, 'id' | 'name'>[];
  published_brands?: Brand[];
  draft_brands?: Brand[];
  version?: number;
  linked_sites?: LocalMenuGroupToSiteRelationship[];
}

export interface Brand extends RecordEntity {
  id: RecordEntityId;
  name: string;
  is_active: boolean;
  global_menu_group_id?: RecordEntityId;
  local_menu_group_id?: RecordEntityId;
  type: MenuRecordType;
  description?: string;
  attachments?: Attachments;
  global_menu_group?: GlobalMenuGroup;
  pending_attachment?: boolean;
  statuses?: BrandStatus[];
  parent_id?: RecordEntityId;
  version?: number;
  is_simplified_view: boolean;
  frictionless_partner?: FrictionlessPartner;
  is_jwo: boolean;
  menus?: Menu[];
  catalog_id?: number;
  meta?: BrandMeta;
}

export interface BrandMeta {
  jde_category: string;
}

export enum FrictionlessPartner {
  AWS = 'AWS',
}

export enum MenuRecordType {
  GLOBAL = 'global',
  LOCAL = 'local',
}

export interface Attachments {
  thumbnail?: any;
}

export enum ImportValidationMetaDataTypes {
  INITIALIZED = 'Validation Report Initialized',
  IN_PROGRESS = 'Excel Validation In Progress',
  DTO_VALIDATION_COMPLETE = 'DTO Validation Complete',
  ID_VALIDATION_COMPLETE = 'Item ID Validation Complete',
  CATEGORY_VALIDATION_COMPLETE = 'Category ID Validation Complete',
  EXCEL_VALIDATION_COMPLETE = 'Excel Validation Complete',
}
export interface BrandStatusMetaData {
  s3_link: string;
  message: string;
}

export interface BrandStatus {
  brand_id: RecordEntityId;
  id: RecordEntityId;
  status: BrandStatusSeverity;
  type: BrandStatusType;
  metadata?: BrandStatusMetaData;
}

export enum BrandStatusSeverity {
  OK = 'successful',
  INITIATED = 'initiated',
  IN_PROGRESS = 'in_progress',
  ERROR = 'failed',
}

export enum BrandStatusType {
  PUBLISH = 'publish',
  POSID = 'posid_generation',
  APPLY_DIFF = 'apply_diff',
  APPLYING_IMPORT = 'import_excel',
  VALIDATE_EXCEL = 'validate_excel',
}

export enum DiffAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum DiffEntityType {
  MODIFIER = 'modifier',
  MODIFIER_GROUP = 'modifier_group',
  ITEM = 'item',
  CATEGORY = 'category',
  VIEW = 'layout',
}

export interface BrandDiffRecord extends RecordEntity {
  action: DiffAction;
  entity_type: DiffEntityType;
  local_id: RecordEntityId;
  changes: Modifier | ModifierGroup | Item | Category | Menu;
  local_snapshot:
    | Modifier
    | ModifierGroup
    | Item
    | Category
    | Menu
    | CategoryToItemRelationship
    | ItemToModifierGroupRelationship
    | ModifierGroupToModifierRelationship;
  global_id: RecordEntityId;
  global_snapshot:
    | Modifier
    | ModifierGroup
    | Item
    | Category
    | Menu
    | CategoryToItemRelationship
    | ItemToModifierGroupRelationship
    | ModifierGroupToModifierRelationship;
  brand_id: RecordEntityId;
  depends_on: BrandDiffRecord[];
  child_nodes: BrandDiffRecord[];
  name: string;
}

export interface BrandDiff {
  id: string;
  overwrite: boolean;
}

export interface BrandDiffPayload {
  diffs: BrandDiff[];
}

export const defaultBrandNavigationPaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};
